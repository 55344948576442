import { makeStyles } from '@fluentui/react-components';
import { InfoRegular } from '@fluentui/react-icons';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Account } from '@zastrpay/accounts';
import { BulletList, Dialog, FormattedCurrency, Link } from '@zastrpay/components';
import { MessagePage } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

import { TransactionData } from './models';

export type WalletTransferInsufficientFundsProps = {
    transactionData: TransactionData;
    account?: Account;
    onContinue?: () => void;
};

export const WalletTransferInsufficientFunds: React.FC<WalletTransferInsufficientFundsProps> = ({
    transactionData,
    account,
    onContinue,
}) => {
    const { t } = useTranslation('transactions');
    const classes = useStyles();
    const [showHowTo, setShowHowTo] = useState(false);

    return (
        <>
            <MessagePage
                icon={<InfoRegular />}
                title={t('walletTransfer.insufficientFunds.title')}
                message={
                    <div className={classes.text}>
                        <Trans
                            t={t}
                            i18nKey="walletTransfer.insufficientFunds.subTitle"
                            components={{
                                infoLink: <Link className={classes.link} inline onClick={() => setShowHowTo(true)} />,
                            }}
                        />
                        <div className={classes.balance}>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="walletTransfer.insufficientFunds.balance"
                                    components={{
                                        balance: (
                                            <FormattedCurrency
                                                className={classes.amount}
                                                currency={account?.currency}
                                                value={account?.balance}
                                                format="sign"
                                            />
                                        ),
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="walletTransfer.insufficientFunds.requestedAmount"
                                    components={{
                                        balance: (
                                            <FormattedCurrency
                                                className={classes.amount}
                                                currency={transactionData?.currency}
                                                value={transactionData?.amount}
                                                format="sign"
                                            />
                                        ),
                                    }}
                                />
                            </li>
                        </div>
                    </div>
                }
                action={t('walletTransfer.insufficientFunds.action')}
                onAction={onContinue}
            />
            <Dialog align="bottom" open={showHowTo} title={t('walletTransfer.howToDialog.title')} onOpenChange={setShowHowTo}>
                <BulletList as="ol">
                    <li>{t('walletTransfer.howToDialog.first')}</li>
                    <li>{t('walletTransfer.howToDialog.second')}</li>
                    <li>{t('walletTransfer.howToDialog.third')}</li>
                    <li>{t('walletTransfer.howToDialog.fourth')}</li>
                </BulletList>
            </Dialog>
        </>
    );
};

const useStyles = makeStyles({
    amount: {
        fontWeight: tokens.fontWeightBold,
    },
    text: {
        display: 'flex',
        flexDirection: 'column',
    },
    link: {
        fontWeight: tokens.fontWeightBold,
    },
    balance: {
        marginTop: tokens.spacingVerticalL,
        marginLeft: tokens.spacingHorizontalS,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
    },
});
