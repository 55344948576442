import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { CookieBanner } from '@zastrpay/analytics';
import { useAuth } from '@zastrpay/auth';
import { useNetworkState } from '@zastrpay/hooks';
import { useKycRequest } from '@zastrpay/kyc-requests';
import { BackButton, Layout as SharedLayout, useLayout } from '@zastrpay/layout';

import { CancelSession } from '../session-cancellation/CancelSession';
import { useSessionNavigation } from './SessionNavigationProvider';

export const Layout: React.FC = () => {
    const navigate = useNavigate();
    const network = useNetworkState();

    const { state, customer } = useAuth();
    const { setHeaderSlot, clearHeaderSlot } = useLayout();
    const { backShown } = useSessionNavigation();

    const { completed: isKycFlowCompleted, loaded } = useKycRequest();

    useEffect(() => {
        if (state === 'error') {
            if (network === 'offline') {
                navigate('/error/network');
            } else {
                navigate('/error');
            }
        } else if (state === 'expired') {
            navigate('/error/expired');
        } else if (customer?.state === 'Blocked' && loaded && isKycFlowCompleted) {
            navigate('/error/blocked');
        }
    }, [state, navigate, network, customer, isKycFlowCompleted, loaded]);

    useEffect(() => {
        if (backShown) {
            const unregisterHandle = setHeaderSlot('left', <BackButton onClick={() => navigate(-1)} />);
            return () => unregisterHandle();
        } else {
            clearHeaderSlot('left');
        }
    }, [navigate, backShown, setHeaderSlot, clearHeaderSlot]);

    useEffect(() => {
        const unregisterHandle = setHeaderSlot('right', <CancelSession dialog="default" />);
        return () => unregisterHandle();
    }, [setHeaderSlot]);

    return (
        <>
            <SharedLayout />

            <CookieBanner />
        </>
    );
};
