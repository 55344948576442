import { Currency } from '@zastrpay/components';

export type TransactionType =
    | 'PassthroughDeposit'
    | 'PassthroughWithdrawal'
    | 'CustomerDeposit'
    | 'CustomerWithdrawal'
    | 'MerchantToCustomerTransfer'
    | 'CustomerToMerchantTransfer';

export type Direction = 'CustomerToMerchant' | 'MerchantToCustomer';

export type RedirectTransactionType =
    | 'PassthroughDeposit'
    | 'PassthroughWithdrawal'
    | 'CustomerDeposit'
    | 'CustomerWithdrawal'
    | 'CustomerToMerchantPassthrough'
    | 'MerchantToCustomerPassthrough'
    | 'MerchantToCustomerTransfer';

export type TransactionData = {
    amount: number;
    currency: Currency;
    type: RedirectTransactionType;
    direction?: Direction;
    externalReference?: string;
};

export type CreateTransaction = {
    type: TransactionType;
    amount: number;
    currency: Currency;
    customerId: string;
    merchantId?: string;
    externalReference?: string;
    locale?: string;
};

export type TransactionState = 'Completed' | 'Declined' | 'Cancelled' | 'PendingApproval';

export type Transaction = {
    id: string;
    transactionIntentId?: string;
    type: TransactionType;
    state: TransactionState;
    customerId: string;
    currency: Currency;
    amount: number;
};

export type ApproveTransaction = {
    customerId: string;
    approvalCode: string;
};

export type Merchant = {
    id: string;
    name: string;
    displayName?: string;
};

export const getTransactionTypeFromRedirectTypeOrDirection = (direction: Direction | undefined, type: RedirectTransactionType) => {
    if (type === 'MerchantToCustomerTransfer') {
        return 'MerchantToCustomerTransfer';
    } else if (direction === 'CustomerToMerchant') {
        return 'CustomerToMerchantTransfer';
    } else if (direction === 'MerchantToCustomer') {
        return 'MerchantToCustomerTransfer';
    }
};

export const getTransactionIntentTypeFromDirection = (direction: Direction | undefined) => {
    if (direction === 'CustomerToMerchant') {
        return 'CustomerToMerchantPassthrough';
    } else {
        return 'MerchantToCustomerPassthrough';
    }
};

export const getTransactionIntentTypeFromRedirectTypeOrDirection = (direction: Direction | undefined, type: RedirectTransactionType) => {
    if (direction) {
        return getTransactionIntentTypeFromDirection(direction);
    } else if (
        type === 'PassthroughDeposit' ||
        type === 'PassthroughWithdrawal' ||
        type === 'CustomerDeposit' ||
        type === 'CustomerWithdrawal' ||
        type === 'CustomerToMerchantPassthrough' ||
        type === 'MerchantToCustomerPassthrough'
    ) {
        return type;
    } else {
        throw new Error(`Unsupported transaction type ${type}`);
    }
};
