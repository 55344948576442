import { makeStyles } from '@fluentui/react-components';
import { MoneyHandRegular } from '@fluentui/react-icons';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useAuth } from '@zastrpay/auth';
import { Button, Dialog, FormattedCurrency } from '@zastrpay/components';
import { MessagePage } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

import {
    CreateTransaction,
    getTransactionIntentTypeFromDirection,
    getTransactionTypeFromRedirectTypeOrDirection,
    Merchant,
    TransactionData,
} from './models';

export type TransactionTypeSelectionProps = {
    transactionData: TransactionData;
    merchant: Merchant;
    onCreateWalletTransfer?: (createTransaction: CreateTransaction) => void;
    onCashSelection?: (transactionData: TransactionData) => void;
};

export const TransactionTypeSelection: React.FC<TransactionTypeSelectionProps> = ({
    transactionData,
    merchant,
    onCreateWalletTransfer,
    onCashSelection,
}) => {
    const classes = useStyles();
    const { t } = useTranslation('transactions');
    const { customerId } = useAuth();
    const [confirmationWalletTransfer, setConfirmationWalletTransfer] = useState(false);

    const onSubmit = () => {
        const createTransaction = {
            amount: transactionData.amount,
            currency: transactionData.currency,
            customerId: customerId,
            merchantId: merchant?.id,
            externalReference: transactionData.externalReference,
            type: getTransactionTypeFromRedirectTypeOrDirection(transactionData.direction, transactionData.type),
        } as CreateTransaction;

        onCreateWalletTransfer?.(createTransaction);
    };

    const cashSelected = () => {
        transactionData.type = getTransactionIntentTypeFromDirection(transactionData.direction);
        onCashSelection?.(transactionData);
    };

    const transactionKind = transactionData.direction ?? transactionData.type;

    switch (transactionKind) {
        case 'CustomerToMerchant':
        case 'MerchantToCustomer':
            break;
        case 'PassthroughDeposit':
        case 'PassthroughWithdrawal':
        case 'CustomerDeposit':
        case 'CustomerWithdrawal':
        case 'CustomerToMerchantPassthrough':
        case 'MerchantToCustomerPassthrough':
        case 'MerchantToCustomerTransfer':
            throw new Error(`Unsupported transaction kind ${transactionKind}`);
    }

    return (
        <>
            <MessagePage
                icon={<MoneyHandRegular />}
                title={
                    <Trans
                        t={t}
                        i18nKey="typeSelection.title"
                        context={transactionKind}
                        components={{
                            amount: <FormattedCurrency currency={transactionData.currency} value={transactionData.amount} format="sign" />,
                        }}
                    />
                }
                message={
                    <div>
                        <Trans
                            t={t}
                            i18nKey="typeSelection.subTitle"
                            context={transactionKind}
                            values={{ merchant: merchant?.displayName ?? merchant?.name }}
                            components={{
                                amount: (
                                    <FormattedCurrency
                                        className={classes.amount}
                                        currency={transactionData.currency}
                                        value={transactionData.amount}
                                        format="sign"
                                    />
                                ),
                            }}
                        ></Trans>
                        <div className={classes.question}>
                            {t('typeSelection.question', {
                                context: transactionKind,
                            })}
                        </div>
                    </div>
                }
            >
                <Button size="large" appearance="primary" onClick={() => setConfirmationWalletTransfer(true)} className={classes.button}>
                    {t('typeSelection.wallet', {
                        context: transactionKind,
                    })}
                </Button>
                <Button size="large" appearance="secondary" onClick={cashSelected} className={classes.button}>
                    {t('typeSelection.cash', {
                        context: transactionKind,
                    })}
                </Button>
            </MessagePage>
            <Dialog
                open={confirmationWalletTransfer}
                align="bottom"
                icon={<MoneyHandRegular />}
                title={t('confirmationDialog.title')}
                onOpenChange={setConfirmationWalletTransfer}
                actions={[{ text: t('confirmationDialog.action'), style: 'preferred', onClick: onSubmit }]}
            ></Dialog>
        </>
    );
};

const useStyles = makeStyles({
    button: {
        marginLeft: tokens.spacingHorizontalL,
        marginRight: tokens.spacingHorizontalL,
        alignSelf: 'stretch',
    },
    amount: {
        fontWeight: tokens.fontWeightBold,
    },

    question: {
        marginTop: tokens.spacingVerticalL,
    },
});
